<template>
  <div class='magic-login__error'>
    <p v-if="authByMagicId.status.value === 'loading'">
      <loader />
    </p>
    <p v-if='!isReady && !authByMagicIdErrors && !authByMagicId.isLoading.value' class='pa-4 fz-20 fw-500'>
      {{ $t('comingSoon')}}
    </p>
    <template v-if='authByMagicIdErrors'>
      <div class='magic-login__error-ico'>
        <Icon name='alert'></Icon>
      </div>
      <p class='magic-login__error-text'>
        {{ $t(`serverError_login_${authByMagicIdErrors?.type}`) }}
      </p>
    </template>
  </div>
</template>

<script setup>
import Loader from '@/components/atoms/Loader.vue';
import Icon from '@/components/atoms/Icon.vue';
import { useRoute, useRouter } from 'vue-router';
import { useAuth } from '@/composable/auth';
import { clearTokens } from '@/helpers/tokens';
import { ref } from 'vue';

const route = useRoute();
const router = useRouter();
const magicId = route.query['magic-id'];
const flow = route.query.flow;
const isReady = ref(false)

const DRIVER_ACCOUNT_TYPE = 403;

clearTokens();
const {authByMagicId, authByMagicIdErrors, updateDriverInfo} = useAuth();
authByMagicId.mutateAsync({ id: magicId }).then(async (resp) => {
  const {email, id, accountTypes} = resp.data.data;
  if (accountTypes.length && accountTypes[0] === DRIVER_ACCOUNT_TYPE) {
    await updateDriverInfo({
      email: email,
      user_id: id
    });
  }
  if (flow === 'invite') {
    isReady.value = true
    router.push({ name: 'Invite', params: route.params });
  }else{
    isReady.value = false
  }
});
</script>
<style lang="scss">
.magic-login {
  &__button{
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 20px 0;
  }
  &__error{
    min-height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    flex-wrap: wrap;
    text-align: center;
    font-size: 16px;
    &-ico {
      width: 100%;
      color: $red;
      margin-bottom: 5px;
      svg {
        width: 80px;
        height: 80px;
      }
    }

    &-text {
      font-size: 18px;
    }
  }
}
</style>
